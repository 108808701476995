import { graphql } from "gatsby";
import * as React from "react";
import { MetadataFields } from "../fragments/NodeMetaFields";
import { PageFields } from "../fragments/NodePageFields";
import { useState } from "react";
import { DocumentFields } from "../fragments/NodeDocumentFields";
import { pdfjs } from "react-pdf";

const Test = ({ data }: { data: { contentfulDocument: DocumentFields } }) => {
  // const [numPages, setNumPages] = useState(null);

  // pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  //   "pdfjs-dist/build/pdf.worker.min.js",
  //   import.meta.url
  // ).toString();

  // const onDocumentLoadSuccess = ({ numPages }) => {
  //   setNumPages(numPages);
  // };
  //   function rewriteAssetUrl(assetUrl: string) {
  //     const parsedUrl = new URL(assetUrl, 'https://ctfassets.net') // accepts URLs with and without scheme
  //     const subdomain = parsedUrl.hostname.split('.')[0] // images, downloads, assets, videos
  //     parsedUrl.hostname = 'dipra.org' // Your asset service's hostname goes here
  //     parsedUrl.pathname = `/${subdomain}${parsedUrl.pathname}`
  //     return parsedUrl.toString()
  // }

  //   console.log("data.home", data.home)

  //   const imageUpdate = rewriteAssetUrl(data.home?.featuredImage?.file?.url)

  //   console.log("imageUPdate", imageUpdate)

  return (
    <>
      {console.log(data?.contentfulDocument?.file?.url)}
      <object
        width="100%"
        data={data?.contentfulDocument?.file?.url}
        type="application/pdf"
        style={{ height: "100vh", minHeight: "100vh" }}>
        <iframe
          width="100%"
          style={{ height: "100vh", minHeight: "100vh" }}
          src={data?.contentfulDocument?.file?.url}>
          <p>Unfortunately, this browser does not support PDF rendering</p>
        </iframe>
      </object>
      {/* <Document
        file={data?.contentfulDocument?.file?.url}
        onLoadSuccess={onDocumentLoadSuccess}>
        {Array.from(new Array(numPages), (el, index) => (
          <Page key={`page_${index + 1}`} pageNumber={index + 1} />
        ))}
      </Document> */}
      <p>test</p>
    </>
  );
};

export default Test;

export interface IndexPageQuery {
  data: {
    site: MetadataFields;
    home: PageFields;
  };
}

export const query = graphql`
  {
    contentfulDocument {
      ...NodeDocumentFields
    }
  }
`;
